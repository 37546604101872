@charset "UTF-8";
.container-fluid {
  padding: 0 !important;
}

.accordion {
  overflow-anchor: none;
}

.jssocials-share {
  display: inline-block;
  padding: 0 8px;
}

.bootstrap-select .dropdown-menu li small {
  white-space: normal;
}

@media only screen and (max-width: 767.98px) {
  .bootstrap-select .dropdown-menu.inner {
    width: 300px;
  }
}

.select-with-live-search .dropdown-menu li a span.text {
  display: inline-block;
  max-width: 500px;
}

@media only screen and (max-width: 767.98px) {
  .autocomplete-suggestions {
    font-size: 12px;
    width: 300px !important;
  }
}

.ulogin-buttons-container {
  width: 100% !important;
  height: unset !important;
}

@media (min-width: 400px) {
  .container_new {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .container_new {
    width: 87%;
  }
}

@media (max-width: 991px) {
  .hidden-sm-new {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md-new {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg-new {
    display: none !important;
  }
}

@media (min-width: 1920px) {
  .container_new {
    width: 1430px !important;
  }
}

#toast-container > div {
  opacity: 1;
}

#toast-container .toast-message {
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: block;
  }
}

.yamm .container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.yamm .yamm-content {
  padding: 10px 15px;
}

@media only screen and (max-width: 991.98px) {
  .yamm .yamm-content {
    padding: 0;
    margin: 0 -10px;
  }
}

.yamm .yamm-content a:focus, .yamm .yamm-content a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.yamm .global-rankings-links {
  min-width: 635px;
}

@media only screen and (max-width: 767.98px) {
  .yamm .global-rankings-links {
    min-width: auto;
  }
}

.yamm .navbar-default .navbar-nav > .open > a,
.yamm .navbar-default .navbar-nav > .open > a:focus,
.yamm .navbar-default .navbar-nav > .open > a:hover {
  color: #555;
  background-color: #fff;
}

.yamm .nav .open > a, .yamm .nav .open > a:focus, .yamm .nav .open > a:hover {
  background-color: #fff;
  border-color: #337ab7;
}

.yamm .dropdown-menu {
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
}

@media only screen and (min-width: 768px) {
  .yamm .dropdown-menu {
    margin: 0;
  }
}

.yamm .dropdown-menu li a {
  color: #666;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.2px;
  /* 82.5% */
  text-transform: none;
  padding: 10px 0;
}

.yamm .dropdown-menu li.dropdown-header {
  color: #444;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.2px;
  /* 82.5% */
  text-transform: none;
  padding: 30px 0;
}

.yamm .dropdown-menu li.dropdown-header_first {
  padding-top: 0;
}

.yamm .navbar-toggle {
  border: none;
  margin-right: 0 !important;
}

.yamm .navbar-toggle_image {
  padding: 5px 10px 9px 10px !important;
  margin-top: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.yamm .navbar-toggle .icon-bar {
  display: block;
  width: 15px;
  height: 2px;
  background-color: #4B49B1;
}

.yamm .dropdown, .yamm .dropdown-toggle, .yamm .navbar ul li a {
  color: #666;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.2px;
  /* 73.333% */
  text-transform: none;
  padding: 0;
}

.yamm .dropdown span img, .yamm .dropdown-toggle span img, .yamm .navbar ul li a span img {
  margin-bottom: 5px;
}

.yamm .dropdown__right {
  margin-right: 58px;
}

.yamm .dropdown__last {
  margin-right: 40px;
}

.yamm .navbar-header_last {
  margin: 3px -10px 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.yamm .navbar-collapse.collapse {
  display: none !important;
}

@media (min-width: 320px) {
  .yamm .navbar-toggle {
    border: none;
  }
  .yamm .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .yamm .dropdown-menu > li > a {
    width: 130%;
    white-space: normal;
  }
  .yamm .dropdown-menu li.dropdown-header {
    padding: 0 !important;
  }
}

@media (max-width: 1199px) {
  .yamm .navbar-nav > li {
    float: none;
  }
  .yamm .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .yamm .navbar-nav .open .dropdown-menu:hover, .yamm .navbar-nav .open .dropdown-menu:focus {
    box-shadow: none;
  }
  .yamm .navbar-nav .dropdown-menu li a, .yamm .nav li a {
    padding: 0 !important;
  }
  .yamm .navbar-nav .dropdown-menu li a:hover, .yamm .nav li a:hover {
    background-color: transparent;
  }
  .yamm .nav .dropdown-menu {
    padding: 0;
  }
  .yamm .nav li.dropdown {
    margin-top: 20px;
  }
  .yamm .nav li.dropdown-header {
    margin: 20px 0 0 10px;
    padding: 0;
  }
  .yamm .nav li > ul > li {
    margin: 15px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .yamm .navbar-header {
    margin-right: 0;
  }
  .yamm .navbar-toggle_image {
    margin-top: 16px;
  }
  .yamm .navbar-header_last {
    margin-left: 0 !important;
    margin-right: -12px !important;
  }
  .yamm .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
    margin: 23px 0 auto auto;
  }
  .yamm .navbar-nav > li > .dropdown-menu {
    margin-top: 15px;
  }
}

.dropdown-menu li a {
  white-space: normal;
}

.modal-lg {
  width: 1200px;
}

.tab-content {
  padding-top: 20px;
}

.organization__block-filters .block-filters__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  color: #ffffff;
  background-color: #4B49B1;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 10px;
}

.organization__block-filters .block-filters__btn .filters__btn-text {
  margin-bottom: 0;
  font-family: 'Raleway', 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 16.8px;
}

.organization__block-filters .block-filters__btn_narrow {
  width: 40px !important;
}

.organization__block-filters .block-filters__radio-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.organization__block-filters .block-filters__radio-group .radio-group__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  white-space: normal;
  font-size: 13px;
  padding: 5px;
}

.organization__block-filters .block-filters__radio-group .radio-group__btn {
  padding: 6px 10px !important;
  margin: 0 0 5px 0 !important;
}

.organization__block-filters .block__filters-apply {
  background: #4B49B1;
  border-radius: 5px;
}

.organization__block-filters .block__filters-apply .filters-apply__text p {
  margin-bottom: 0;
}

.block-filters__modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: -webkit-calc(100% - 57px);
  height: calc(100% - 57px);
}

.block-filters__modal .filters__modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  border-bottom: none !important;
}

.block-filters__modal .filters__modal-header .header__row_right {
  margin-left: auto;
}

.block-filters__modal .filters__modal-header .header__row {
  font-family: Raleway;
  font-weight: 500;
  line-height: 16px;
}

.block-filters__modal .filters__modal-header .header__row .row__text-with-margin-left {
  margin-left: 10px;
}

.block-filters__modal .filters__modal-header .header__row .row__input {
  border-radius: 10px;
  border: 1px solid #F4F4F4;
  background: #FFF;
  color: #BBB;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 8px 10px;
  width: 100%;
}

.block-filters__modal .filters__modal-header .header__row .row__input:focus, .block-filters__modal .filters__modal-header .header__row .row__input:active, .block-filters__modal .filters__modal-header .header__row .row__input:hover {
  border: 1px solid #4B49B1;
}

.block-filters__modal .filters__modal-header .header__row_1-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.3%;
      -ms-flex: 1 1 33.3%;
          flex: 1 1 33.3%;
}

.block-filters__modal .filters__modal-header .header__row_2-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.6%;
      -ms-flex: 1 1 66.6%;
          flex: 1 1 66.6%;
}

.block-filters__modal .filters__modal-header .header__row_close {
  margin-left: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.block-filters__modal .filters__modal-header .header__row_long {
  width: 100%;
}

.block-filters__modal .filters__modal-header .close {
  opacity: 1 !important;
  -webkit-border-radius: 10px;
  -webkit-appearance: none;
  padding: 0;
  line-height: 0;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.block-filters__modal .filters__modal-header .close::before {
  position: absolute;
  content: '';
  width: 11px;
  height: 2px;
  background-color: #4B49B1;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.block-filters__modal .filters__modal-header .close::after {
  position: absolute;
  content: '';
  width: 11px;
  height: 2px;
  background-color: #4B49B1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.block-filters__modal .filters__modal-body {
  display: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
}

.block-filters__modal .filters__modal-body .modal-body__kvalif, .block-filters__modal .filters__modal-body .modal-body__vi, .block-filters__modal .filters__modal-body .modal-body__edu_form, .block-filters__modal .filters__modal-body .modal-body__source_type label {
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  color: #1D1D1F;
}

.block-filters__modal .filters__modal-body .bootstrap-select {
  width: 100%;
}

.block-filters__modal .filters__modal-body .bootstrap-select button {
  border-radius: 10px;
}

.block-filters__modal .filters__modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: auto;
  padding-bottom: 10px;
}

.block-item__title {
  font-weight: 300;
  color: #1D1D1F;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.block-item__name {
  margin-bottom: 10px !important;
}

.block-item__arrow {
  min-width: 30px;
  height: inherit;
}

.item__competitions-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-flow: column nowrap !important;
      -ms-flex-flow: column nowrap !important;
          flex-flow: column nowrap !important;
  gap: 5px;
}

.dropdown_hidden {
  display: none;
}

.block-item__nowrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-flow: row nowrap !important;
      -ms-flex-flow: row nowrap !important;
          flex-flow: row nowrap !important;
  gap: 15px;
}

.block-item__competitions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 15px;
}

.block-item__competitions_for-tab-pane {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
}

.block-item__competitions_for-tab-pane .tab-pane-inner {
  padding: 20px;
  overflow-x: auto;
}

#dropdown_competitions {
  min-height: 140px;
}

.block-selected__btn .selected__btn-cancel {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: Roboto, serif;
  font-weight: 300;
  line-height: 120%;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  color: #4B49B1;
}

@media screen and (min-width: 320px) {
  .organization__title {
    margin-bottom: 20px;
  }
  .mobile-hidden {
    display: none;
  }
  #dropdown_competitions {
    cursor: pointer;
  }
  .filter-desktop {
    display: none;
  }
  .filter-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-bottom: 20px;
  }
  .organization__block-selected {
    display: none;
  }
  .block-filters__modal {
    padding: 0 20px;
  }
  .block-filters__modal .filters__modal-header {
    padding: 23px 20px 20px 20px;
  }
}

@media screen and (min-width: 992px) {
  .block-filters__modal {
    padding: 0 20px;
  }
  .block-filters__modal .filters__modal-header {
    padding: 19px 80px 20px 80px;
  }
  .dropdown_hidden {
    display: none;
  }
  #dropdown_competitions {
    display: none;
  }
  .mobile-hidden {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .organization__block-item {
    margin: 10px;
  }
  .filter-mobile {
    display: none;
  }
  .filter-desktop {
    margin: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .organization__block-selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    gap: 10px;
    margin: 10px 10px 27px 10px;
  }
  .organization__block-selected .block-selected__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 112px;
    width: auto;
    height: 29px;
    background: #4b49b1;
    border-radius: 10px;
    text-align: center;
  }
  .organization__block-selected .block-selected__item .selected__item-text {
    text-align: left;
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Roboto, 'Roboto', 'Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    color: white;
  }
  .organization__block-selected .block-selected__item .selected__item-cancel {
    cursor: pointer;
  }
}

.bootstrap-select .btn {
  cursor: default;
  border: 1px solid #dddddd;
  height: 40px;
  color: #555555;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
  line-height: 20px;
  padding: 6px 12px 6px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bootstrap-select .btn .caret {
  color: #dddddd;
}

.open > .dropdown-toggle.btn-default:focus {
  background-color: white;
  border: 1px solid #4B49B1;
}

.open > .dropdown-menu {
  margin-top: 10px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  background-color: white;
  border: 1px solid #4B49B1;
}

.bootstrap-select .btn:hover {
  background-color: white;
  border: 1px solid #4B49B1;
}

.bootstrap-select .btn:focus {
  background-color: white;
  box-shadow: none;
  border: 1px solid #4b49b1;
  outline-color: white !important;
}

.bootstrap-select .btn:active {
  background-color: white;
  box-shadow: none;
}

.filter-option-inner-inner {
  color: #555555;
  height: 26px;
  padding-top: 3px;
}

.open > .dropdown-toggle.btn-default {
  background: white;
  border: 1px solid #dddddd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.bootstrap-select .dropdown-menu {
  margin-top: 10px;
  box-shadow: 0 5px 15px rgba(75, 73, 177, 0.2);
  border-radius: 10px;
  width: inherit;
}

.bootstrap-select .dropdown-menu {
  margin-top: 10px;
  box-shadow: 0 5px 15px rgba(75, 73, 177, 0.2);
  border-radius: 10px;
  width: auto;
}

.organization__block-filters .block-filters__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: auto;
  color: #ffffff;
  background-color: #4B49B1;
  cursor: pointer;
  width: 100px;
  height: 40px;
  border-radius: 10px;
}

.organization__block-filters .block-filters__btn .filters__btn-text {
  margin-bottom: 0;
  font-family: 'Raleway', 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 16.8px;
}

.organization__block-filters .block-filters__btn_narrow {
  width: 40px !important;
}

.organization__block-filters .block-filters__radio-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.organization__block-filters .block-filters__radio-group .radio-group__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  white-space: normal;
  font-size: 13px;
  padding: 5px;
}

.organization__block-filters .block-filters__radio-group .radio-group__btn {
  padding: 6px 10px !important;
  margin: 0 0 5px 0 !important;
}

.organization__block-filters .block__filters-apply {
  background: #4B49B1;
  border-radius: 5px;
}

.organization__block-filters .block__filters-apply .filters-apply__text p {
  margin-bottom: 0;
}

.block-filters__modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: -webkit-calc(100% - 57px);
  height: calc(100% - 57px);
}

.block-filters__modal .filters__modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  border-bottom: none !important;
}

.block-filters__modal .filters__modal-header .header__row_right {
  margin-left: auto;
}

.block-filters__modal .filters__modal-header .header__row {
  font-family: Raleway;
  font-weight: 500;
  line-height: 16px;
}

.block-filters__modal .filters__modal-header .header__row .row__text-with-margin-left {
  margin-left: 10px;
}

.block-filters__modal .filters__modal-header .header__row .row__input {
  border-radius: 10px;
  border: 1px solid #F4F4F4;
  background: #FFF;
  color: #BBB;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 8px 10px;
  width: 100%;
}

.block-filters__modal .filters__modal-header .header__row .row__input:focus, .block-filters__modal .filters__modal-header .header__row .row__input:active, .block-filters__modal .filters__modal-header .header__row .row__input:hover {
  border: 1px solid #4B49B1;
}

.block-filters__modal .filters__modal-header .header__row_1-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 33.3%;
      -ms-flex: 1 1 33.3%;
          flex: 1 1 33.3%;
}

.block-filters__modal .filters__modal-header .header__row_2-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 66.6%;
      -ms-flex: 1 1 66.6%;
          flex: 1 1 66.6%;
}

.block-filters__modal .filters__modal-header .header__row_close {
  margin-left: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.block-filters__modal .filters__modal-header .header__row_long {
  width: 100%;
}

.block-filters__modal .filters__modal-header .close {
  opacity: 1 !important;
  -webkit-border-radius: 10px;
  -webkit-appearance: none;
  padding: 0;
  line-height: 0;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.block-filters__modal .filters__modal-header .close::before {
  position: absolute;
  content: '';
  width: 11px;
  height: 2px;
  background-color: #4B49B1;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.block-filters__modal .filters__modal-header .close::after {
  position: absolute;
  content: '';
  width: 11px;
  height: 2px;
  background-color: #4B49B1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.block-filters__modal .filters__modal-body {
  display: inherit;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
}

.block-filters__modal .filters__modal-body .modal-body__kvalif, .block-filters__modal .filters__modal-body .modal-body__vi, .block-filters__modal .filters__modal-body .modal-body__edu_form, .block-filters__modal .filters__modal-body .modal-body__source_type label {
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 120%;
  color: #1D1D1F;
}

.block-filters__modal .filters__modal-body .bootstrap-select {
  width: 100%;
}

.block-filters__modal .filters__modal-body .bootstrap-select button {
  border-radius: 10px;
}

.block-filters__modal .filters__modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: auto;
  padding-bottom: 10px;
}

.block-item__title {
  font-weight: 300;
  color: #1D1D1F;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.block-item__name {
  margin-bottom: 10px !important;
}

.block-item__arrow {
  min-width: 30px;
  height: inherit;
}

.item__competitions-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-flow: column nowrap !important;
      -ms-flex-flow: column nowrap !important;
          flex-flow: column nowrap !important;
  gap: 5px;
}

.dropdown_hidden {
  display: none;
}

.block-item__nowrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-flow: row nowrap !important;
      -ms-flex-flow: row nowrap !important;
          flex-flow: row nowrap !important;
  gap: 15px;
}

.block-item__competitions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 15px;
}

.block-item__competitions_for-tab-pane {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-radius: 0 0 10px 10px;
}

.block-item__competitions_for-tab-pane .tab-pane-inner {
  padding: 20px;
  overflow-x: auto;
}

#dropdown_competitions {
  min-height: 140px;
}

.block-selected__btn .selected__btn-cancel {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: Roboto, serif;
  font-weight: 300;
  line-height: 120%;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  color: #4B49B1;
}

@media screen and (min-width: 320px) {
  .organization__title {
    margin-bottom: 20px;
  }
  .mobile-hidden {
    display: none;
  }
  #dropdown_competitions {
    cursor: pointer;
  }
  .filter-desktop {
    display: none;
  }
  .filter-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-bottom: 20px;
  }
  .organization__block-selected {
    display: none;
  }
  .block-filters__modal {
    padding: 0 20px;
  }
  .block-filters__modal .filters__modal-header {
    padding: 23px 20px 20px 20px;
  }
}

@media screen and (min-width: 992px) {
  .block-filters__modal {
    padding: 0 20px;
  }
  .block-filters__modal .filters__modal-header {
    padding: 19px 80px 20px 80px;
  }
  .dropdown_hidden {
    display: none;
  }
  #dropdown_competitions {
    display: none;
  }
  .mobile-hidden {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .organization__block-item {
    margin: 10px;
  }
  .filter-mobile {
    display: none;
  }
  .filter-desktop {
    margin: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .organization__block-selected {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    gap: 10px;
    margin: 10px 10px 27px 10px;
  }
  .organization__block-selected .block-selected__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 112px;
    width: auto;
    height: 29px;
    background: #4b49b1;
    border-radius: 10px;
    text-align: center;
  }
  .organization__block-selected .block-selected__item .selected__item-text {
    text-align: left;
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: Roboto, 'Roboto', 'Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    color: white;
  }
  .organization__block-selected .block-selected__item .selected__item-cancel {
    cursor: pointer;
  }
}

.bootstrap-select .btn {
  cursor: default;
  border: 1px solid #dddddd;
  height: 40px;
  color: #555555;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
  line-height: 20px;
  padding: 6px 12px 6px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.bootstrap-select .btn .caret {
  color: #dddddd;
}

.open > .dropdown-toggle.btn-default:focus {
  background-color: white;
  border: 1px solid #4B49B1;
}

.open > .dropdown-menu {
  margin-top: 10px;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  background-color: white;
  border: 1px solid #4B49B1;
}

.bootstrap-select .btn:hover {
  background-color: white;
  border: 1px solid #4B49B1;
}

.bootstrap-select .btn:focus {
  background-color: white;
  box-shadow: none;
  border: 1px solid #4b49b1;
  outline-color: white !important;
}

.bootstrap-select .btn:active {
  background-color: white;
  box-shadow: none;
}

.filter-option-inner-inner {
  color: #555555;
  height: 26px;
  padding-top: 3px;
}

.open > .dropdown-toggle.btn-default {
  background: white;
  border: 1px solid #dddddd;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.bootstrap-select .dropdown-menu {
  margin-top: 10px;
  box-shadow: 0 5px 15px rgba(75, 73, 177, 0.2);
  border-radius: 10px;
  width: inherit;
}

.bootstrap-select .dropdown-menu {
  margin-top: 10px;
  box-shadow: 0 5px 15px rgba(75, 73, 177, 0.2);
  border-radius: 10px;
  width: auto;
}

.modal-fullscreen {
  padding: 0 !important;
  width: 100vw;
}

.modal-fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  min-height: 100%;
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
}

.autocomplete-suggestions {
  box-shadow: 0 5px 15px rgba(75, 73, 177, 0.2);
  border: 1px solid #DDDDDD !important;
  margin-top: 10px;
  color: #555;
  padding: 10px;
  border-radius: 5px;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.autocomplete-suggestions strong {
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
  color: #4B49B1 !important;
}

@media only screen and (max-width: 991.98px) {
  .navbar-collapse {
    padding: 0;
  }
}

.header {
  padding: 15px 0;
}

.header .inactive-item {
  color: #999;
}

header.menu_fixed {
  padding: 15px 0;
}

.header-banner {
  margin-top: 20px;
}

.header-banner a {
  display: block;
}

.header-banner a img {
  width: 100%;
}

.navbar-default {
  background-color: #ffffff;
  border: none;
}

.navbar {
  margin: 0;
}

.navbar ul li a {
  color: #787878;
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1199.98px) {
  .navbar-nav {
    margin: 0;
  }
  .navbar ul li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991.98px) {
  .navbar-nav {
    margin: 0;
  }
  .navbar-collapse.in {
    overflow-y: unset;
    max-width: 100%;
  }
  .navbar ul li {
    word-break: break-all;
  }
  .navbar ul li a {
    word-break: normal;
    white-space: normal;
    font-size: 12px;
  }
  .navbar-collapse {
    padding-left: 20px;
  }
}

.menu_fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  top: 0;
  padding: 0;
}

.animated {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
}

.header-vuzRyadom {
  background-color: #ffffff;
  padding: 0;
}

.header-vuzRyadom .header-vuzRyadom__inner {
  position: relative;
  padding: 28px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 33%;
}

@media screen and (max-width: 399px) {
  .header-vuzRyadom .header-vuzRyadom__inner {
    padding: 10px;
  }
}

.header-vuzRyadom .header-vuzRyadom__brand {
  color: #66579F;
  font-family: Manrope, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  padding: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

@media screen and (max-width: 399px) {
  .header-vuzRyadom .header-vuzRyadom__brand {
    font-size: 18px;
  }
}

.header-vuzRyadom .header-vuzRyadom__brand:hover {
  text-decoration: none;
}

.header-vuzRyadom .header-vuzRyadom__brand .brand__part2 {
  color: #E64453;
}

.header-vuzRyadom .header-vuzRyadom__logo {
  position: absolute;
  top: 0;
  left: -webkit-calc(50% - 52px);
  left: calc(50% - 52px);
  z-index: 1000;
}

@media screen and (max-width: 399px) {
  .header-vuzRyadom .header-vuzRyadom__logo {
    left: -webkit-calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
  }
}

.header-vuzRyadom .header-vuzRyadom__bestedu-brand {
  color: #4B49B1;
  font-family: Poppins, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.2px;
  /* 73.333% */
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

@media screen and (max-width: 399px) {
  .header-vuzRyadom .header-vuzRyadom__bestedu-brand {
    font-size: 18px;
  }
}

.header-vuzRyadom .header-vuzRyadom__bestedu-brand:hover {
  text-decoration: none;
}

.header-vuzRyadom .navbar-toggle {
  border: none;
  margin: 0 0 0 20px;
  padding: 0;
}

@media screen and (max-width: 399px) {
  .header-vuzRyadom .navbar-toggle {
    margin: 8px 0 0 10px;
  }
}

.header-vuzRyadom .navbar-toggle_image {
  padding: 5px 10px 9px 10px !important;
  margin-top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header-vuzRyadom .navbar-toggle .icon-bar {
  display: block;
  width: 15px;
  height: 2px;
  background-color: #4B49B1;
}

.navbar-brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 0 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar-brand img {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 35px;
      -ms-flex: 0 0 35px;
          flex: 0 0 35px;
  width: 35px;
  height: 35px;
  margin-right: 3px;
}

.navbar-brand .navbar-brand__box {
  width: 262px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navbar-brand .navbar-brand__box img {
  width: 60px;
  height: 60px;
}

.navbar-brand .navbar-brand__box .box__vertical-divider {
  height: 50px;
  border-left: 1px solid #4B49B1;
  margin: 5px 0 0 3px;
}

.navbar-brand .navbar-brand__box .box__vertical-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.navbar-brand .navbar-brand__box .box__text {
  color: #4B49B1;
  font-family: Poppins, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.2px;
  /* 73.333% */
}

.navbar-brand .navbar-brand__box .box__description {
  width: 80px;
  color: #BBB;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 7px;
}

.breadcrumb {
  font-family: 'Raleway', 'Roboto', sans-serif;
  border-radius: 5px;
  background-color: initial;
  font-size: 16px;
  padding: 7px 0;
  font-weight: 300;
  line-height: 29px;
  margin-bottom: 0;
}

.breadcrumb .breadcrumb__item-truncate {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb li {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.breadcrumb li a {
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
  color: #4B49B1;
}

.breadcrumb li + li:before {
  border: solid #4B49B1;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
  content: '';
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.breadcrumb .active {
  color: #1D1D1F;
}

@media only screen and (max-width: 768px) {
  .breadcrumb .active {
    display: none;
  }
}

#pageUp {
  width: 40px;
  height: 40px;
  color: #FFF;
  font-size: 35px;
  background: #4b49b1;
  cursor: pointer;
  z-index: 100;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  right: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  padding: 0 0 8px 0;
  line-height: 0;
}

@media only screen and (min-width: 320px) {
  #pageUp {
    right: 10px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 992px) {
  #pageUp {
    right: 30px;
    bottom: 60px;
  }
}

@media only screen and (min-width: 1920px) {
  #pageUp {
    right: 30px;
    bottom: 80px;
  }
}

#pageUp:active, #pageUp:hover, #pageUp:active:before, #pageUp:hover:before {
  border-color: #FFF;
}

#pageUp.show {
  visibility: visible;
}

@media only screen and (min-width: 320px) {
  #pageUp.show {
    opacity: 0.9;
  }
}

@media only screen and (min-width: 992px) {
  #pageUp.show {
    opacity: 1;
  }
}

.stack-loader {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(9, 68, 62, 0.3);
  border-radius: 50%;
  margin: auto;
  border-top-color: #405369;
  -webkit-animation: spin 1.5s ease-in-out infinite;
          animation: spin 1.5s ease-in-out infinite;
}

.stack-loader-text {
  text-align: center;
  color: #405369;
  text-transform: uppercase;
  font-weight: bold;
}

.stack-loader-wrapper {
  padding-top: 30px;
  position: relative;
}

.stack-loader-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 500px;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.trober {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 200;
}

.trober.active {
  opacity: 1;
  pointer-events: all;
}

.trober__content {
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  max-height: 50%;
  width: 30%;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  color: #8f8f8f;
  margin: 0 0 20vh 0;
}

.trober__content.active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.top-header {
  height: 29px;
  background: #10212B;
  color: whitesmoke;
  height: 30px;
}

.top-header .top-header__accent {
  color: rgba(255, 184, 104, 0.78);
}

.top-header .top-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@supports (display: flex) {
  @media screen and (max-width: 1200px) and (min-width: 770px) {
    .top-header .top-header-wrapper .top-header-contacts-wrapper {
      display: none;
    }
  }
}

.top-header ul {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
}

.top-header ul li {
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 5px;
}

@media only screen and (min-width: 992px) {
  .top-header ul li {
    margin-right: 15px;
  }
}

.top-header a {
  color: #959595;
}

.top-header a:hover {
  color: whitesmoke;
}

.top-header div {
  font-size: 12px;
}

.top-line {
  margin: 0;
  border: none;
  /* Убираем границу для браузера Firefox */
  color: #4B49B1;
  /* Цвет линии для остальных браузеров */
  background-color: #4B49B1;
  /* Цвет линии для браузера Firefox и Opera */
  height: 1.3px;
}

.ranking-table thead {
  background-color: #1b3a4b;
  color: whitesmoke;
}

.content-header {
  color: #F7F7FE;
  background: url("~@img/landing/first_screen_bg_new.webp") no-repeat;
  background-size: cover;
  padding: 20px 0;
}

.content-header .content-header__title {
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.content-header .content-header__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.content-header .content-header__body p, .content-header .content-header__body div {
  color: #222;
  line-height: 150%;
}

.content-header .content-header__body p {
  padding-top: 20px;
}

.content-header .content-header__tabs-wrapper {
  overflow: auto;
}

.content-header .content-header__tabs-wrapper .tabs-wrapper__tabs {
  color: #1D1D1F;
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  line-height: 17.143px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 20px 0 0 0;
  border: none;
}

.content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li {
  margin: 0 0 0 0;
}

.content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li > a {
  color: #1D1D1F;
  padding: 9px 20px 9px 20px;
  white-space: nowrap;
}

.content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li > a:focus, .content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li.active > a, .content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li.active > a:focus, .content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li.active > a:hover {
  border: 1px #4B49B1 solid;
  border-radius: 5px;
  color: #4B49B1;
  font-weight: 500;
  background-color: transparent;
}

.content-header .content-header__tabs-wrapper .tabs-wrapper__tabs li > a:hover {
  border: 1px transparent solid;
  background-color: transparent;
}

.content-header .content-header__share {
  padding: 5px 0 0 0;
  margin: 0 0 0 0;
}

.content-header .content-header__share .jssocials {
  padding: 0 0 0 0;
  margin: -3px 2px 0 0;
}

.content-header .content-header__share .share__link-button {
  text-decoration: none;
  color: #4B49B1;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.143px;
  white-space: nowrap;
  padding: 5px 0 0 0;
}

.content-header .content-header__share .share__link-button .link-button__text {
  display: none;
  margin: 0 10px 0 0;
}

.content-header .content-header__share_without-padding {
  padding: 0 0 0 0 !important;
}

.content-header .content-header__share_without-padding .jssocials {
  margin: -5px 2px 0 0 !important;
}

@media only screen and (min-width: 320px) {
  .content-header .content-header__tabs-wrapper .tabs-wrapper__tabs {
    font-size: 14px;
  }
  .content-header .content-header__title {
    max-width: 82.5%;
    font-size: 18px;
  }
  .content-header .content-header__share .jssocials {
    display: none;
  }
  .content-header .content-header__share:hover .jssocials {
    display: block;
    position: fixed;
  }
  .content-header .content-header__share:hover .jssocials .jssocials-shares {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    left: -8px;
    top: 20px;
  }
  .content-header .content-header__share:hover .jssocials .jssocials-shares .jssocials-share {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 992px) {
  .content-header .content-header__tabs-wrapper .tabs-wrapper__tabs {
    font-size: 18px;
  }
  .content-header .content-header__title {
    max-width: 100%;
    font-size: 26px;
  }
  .content-header .content-header__share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .content-header .content-header__share .jssocials {
    opacity: 0;
  }
  .content-header .content-header__share:hover .jssocials {
    display: block;
    opacity: 1;
    position: static;
  }
  .content-header .content-header__share:hover .jssocials .jssocials-shares {
    position: static;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .content-header .content-header__share:hover .jssocials .jssocials-shares .jssocials-share {
    margin-left: 0;
  }
  .content-header .content-header__share .share__link-button .link-button__text {
    display: inline-block;
  }
}

.accent-btn {
  background: linear-gradient(87.8deg, #802DB1 6.67%, #4C49B2 99.34%);
  border-radius: 15px;
  font-size: 16px;
  color: white;
  padding: 8px 22px;
  margin: 0 10px 15px 0;
}

.accent-btn:hover {
  background: linear-gradient(87.8deg, #802DB1 99.99%, #4C49B2 100%);
  color: white;
  text-decoration: none;
}

.mobile-modal-btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #838383;
}

.sidebar .sidebar__line {
  margin: 5px;
}

.sidebar .sidebar__block {
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
}

.sidebar .sidebar__block:not(:last-child) {
  margin-bottom: 5px;
}

.sidebar .sidebar__filter {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199.98px) {
  .sidebar .sidebar__filter {
    padding-bottom: 10px;
  }
}

.sidebar .sidebar__filter .filter__lock-filter {
  color: #4b49b1;
  float: right;
  margin-top: 4px;
}

.sidebar .sidebar__filter .tooltip a {
  color: #fff;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.sidebar .sidebar__filter .checkbox {
  margin-top: 4px;
  margin-bottom: 0;
}

.sidebar .sidebar__filter .checkbox label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.sidebar .sidebar__filter .checkbox label .small {
  padding-top: 9px;
}

.sidebar .sidebar__filter label {
  margin-bottom: 10px;
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter .filter__require {
  color: #c0504d;
  cursor: pointer;
}

.sidebar .sidebar__filter select, .sidebar .sidebar__filter option, .sidebar .sidebar__filter input, .sidebar .sidebar__filter textarea, .sidebar .sidebar__filter .bs-placeholder, .sidebar .sidebar__filter .bs-searchbox input, .sidebar .sidebar__filter .bootstrap-select .btn {
  border-radius: 5px;
  border: 1px solid #DDD;
  background: #FFF;
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter select:focus, .sidebar .sidebar__filter select:hover, .sidebar .sidebar__filter option:focus, .sidebar .sidebar__filter option:hover, .sidebar .sidebar__filter input:focus, .sidebar .sidebar__filter input:hover, .sidebar .sidebar__filter textarea:focus, .sidebar .sidebar__filter textarea:hover, .sidebar .sidebar__filter .bs-placeholder:focus, .sidebar .sidebar__filter .bs-placeholder:hover, .sidebar .sidebar__filter .bs-searchbox input:focus, .sidebar .sidebar__filter .bs-searchbox input:hover, .sidebar .sidebar__filter .bootstrap-select .btn:focus, .sidebar .sidebar__filter .bootstrap-select .btn:hover {
  box-shadow: none;
  border: 1px solid #4b49b1;
}

.sidebar .sidebar__filter select:disabled, .sidebar .sidebar__filter option:disabled, .sidebar .sidebar__filter input:disabled, .sidebar .sidebar__filter textarea:disabled, .sidebar .sidebar__filter .bs-placeholder:disabled, .sidebar .sidebar__filter .bs-searchbox input:disabled, .sidebar .sidebar__filter .bootstrap-select .btn:disabled {
  background: #DDD;
}

.sidebar .sidebar__filter select, .sidebar .sidebar__filter option, .sidebar .sidebar__filter input, .sidebar .sidebar__filter .bs-placeholder, .sidebar .sidebar__filter .bs-searchbox input, .sidebar .sidebar__filter .bootstrap-select .btn {
  height: 30px;
}

.sidebar .sidebar__filter .bootstrap-select .btn {
  padding: 2px 12px;
}

.sidebar .sidebar__filter .apply-multiple-select__btn {
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 23px;
}

.sidebar .sidebar__filter select, .sidebar .sidebar__filter .filter__label-radio, .sidebar .sidebar__filter input[type="radio"], .sidebar .sidebar__filter .bs-placeholder, .sidebar .sidebar__filter .bs-searchbox input, .sidebar .sidebar__filter .dropdown-menu {
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter .filter-option-inner-inner {
  color: #1d1d1f;
}

.sidebar .sidebar__filter .bs-placeholder {
  height: 30px !important;
  padding-top: 2px !important;
}

.sidebar .sidebar__filter .bootstrap-select .btn .caret {
  color: #1D1D1F;
}

.sidebar .sidebar__filter select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  background-position: -webkit-calc(100% - 12px) 12px;
  background-position: calc(100% - 12px) 12px;
  background-size: 9px 6px;
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M4.5 5L0.602885 0.5L8.39711 0.500001L4.5 5Z' fill='%231D1D1F'/%3E %3C/svg%3E");
}

.sidebar .sidebar__filter input[type="text"]::-webkit-input-placeholder {
  color: #666;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter input[type="text"]::-moz-placeholder {
  color: #666;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter input[type="text"]:-ms-input-placeholder {
  color: #666;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filter input[type="text"]::placeholder {
  color: #666;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px;
}

.sidebar .sidebar__filters.open {
  display: block;
}

.sidebar .sidebar__promo {
  margin: 25px 20px 0 20px;
  text-align: center;
  line-height: 15px;
}

.sidebar .sidebar__promo .sidebar__promo-image {
  margin: 0 0 10px 0;
}

.sidebar .sidebar__promo .sidebar__promo-link .sidebar__promo-link-inner {
  display: inline-block;
}

.sidebar .sidebar__promo .sidebar__promo-link .sidebar__promo-link-inner a {
  color: #024D83;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  font-family: Raleway;
}

.sidebar .sidebar__promo a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1169px) {
  .sidebar .sidebar__promo {
    display: none;
  }
}

.apply-multiple-select {
  padding: 5px 7px;
}

.apply-multiple-select .apply-multiple-select__btn {
  width: 100%;
}

@media only screen and (min-width: 320px) {
  .sidebar {
    display: none;
  }
  .sidebar.open {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .sidebar.open .sidebar__inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .sidebar.open .sidebar__inner .sidebar__block {
    border: none !important;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 90%;
        -ms-flex: 1 1 90%;
            flex: 1 1 90%;
  }
  .sidebar .sidebar__filter {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .sidebar {
    display: block;
  }
  .sidebar .sidebar__inner {
    position: -webkit-sticky;
    position: sticky;
    top: 87px;
  }
  .sidebar .sidebar__block {
    padding: 20px;
  }
}

.dialog {
  padding-top: 50px;
}

.feedback-form .feedback-form__title {
  text-align: center;
  padding-bottom: 5px;
}

.feedback-form .feedback-form__description {
  line-height: 14px;
  text-align: center;
  padding-bottom: 15px;
}

.feedback-form .feedback-form__errors {
  padding: 0 15px;
  font-size: 14px;
}

.feedback-form .feedback-form__submit-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.feedback-form .feedback-form__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
}

@media only screen and (max-width: 767.98px) {
  .feedback-form .feedback-form__row {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.feedback-form .feedback-form__label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  margin-right: 20px;
}

.feedback-form .feedback-form__required-mark {
  color: #c0504d;
  cursor: pointer;
}

.show-feedback-modal-btn {
  text-align: center;
  font-size: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  padding: 0 0 3px 0;
  line-height: 0;
  width: 40px;
  right: 10px;
  height: 40px;
  z-index: 100;
  cursor: pointer;
  visibility: visible;
  border-radius: 50%;
  background: #4b49b1;
  color: white;
}

@media only screen and (min-width: 320px) {
  .show-feedback-modal-btn {
    right: 10px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .show-feedback-modal-btn {
    right: 30px;
    bottom: 10px;
  }
}

@media only screen and (min-width: 1920px) {
  .show-feedback-modal-btn {
    bottom: 30px;
    right: 30px;
  }
}

.show-feedback-modal-btn:active, .show-feedback-modal-btn:hover, .show-feedback-modal-btn:active:before, .show-feedback-modal-btn:hover:before {
  border-color: #342258;
}

@media only screen and (min-width: 320px) {
  .show-feedback-modal-btn:active, .show-feedback-modal-btn:hover, .show-feedback-modal-btn:active:before, .show-feedback-modal-btn:hover:before {
    opacity: 0.9;
  }
}

@media only screen and (min-width: 992px) {
  .show-feedback-modal-btn:active, .show-feedback-modal-btn:hover, .show-feedback-modal-btn:active:before, .show-feedback-modal-btn:hover:before {
    opacity: 1;
  }
}

.report-mistake .report-mistake__text {
  font-size: 13px;
  line-height: 22px;
}

.report-mistake .report-mistake__btn {
  cursor: pointer;
}

footer .newsletter-wrapper {
  position: relative;
}

footer .newsletter-wrapper .newsletter-form {
  position: absolute;
  height: 140px;
  width: 100%;
  color: #1d1f49;
  padding: 0 50px;
  top: -170px;
  background: linear-gradient(95.83deg, #822DB1 7.33%, #4B49B1 51.33%);
  background-size: cover;
  border-radius: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

@media only screen and (max-width: 991.98px) {
  footer .newsletter-wrapper .newsletter-form {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 10px 15px;
  }
}

footer .newsletter-wrapper .newsletter-icon-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 15%;
      -ms-flex: 0 0 15%;
          flex: 0 0 15%;
  margin-right: 37px;
  text-align: center;
}

footer .newsletter-wrapper .newsletter-icon-wrapper img {
  width: 100px;
}

@media only screen and (max-width: 1199.98px) {
  footer .newsletter-wrapper .newsletter-icon-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 991.98px) {
  footer .newsletter-wrapper .newsletter-icon-wrapper {
    margin-right: 0;
  }
}

footer .newsletter-wrapper .newsletter-text-wrapper {
  color: white;
  font-size: 22px;
  line-height: 114%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
      -ms-flex: 0 0 30%;
          flex: 0 0 30%;
  margin-right: 37px;
}

@media only screen and (max-width: 991.98px) {
  footer .newsletter-wrapper .newsletter-text-wrapper {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    margin-right: 0;
    line-height: 100%;
    font-size: 18px;
  }
}

footer .newsletter-wrapper .newsletter-input-text-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-wrapper {
  position: relative;
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-wrapper .newsletter-subscribe-input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 15px;
  padding: 15px;
  font-size: 16px;
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-wrapper .newsletter-subscribe-btn {
  background: #132935;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  height: 40px;
  width: 200px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  color: #FFFFFF;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media only screen and (max-width: 991.98px) {
  footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-wrapper .newsletter-subscribe-btn {
    font-size: 16px;
    width: 120px;
  }
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-wrapper .newsletter-subscribe-btn:hover {
  background: #492d71;
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-error {
  color: #e26880;
  padding: 0 15px;
  font-size: 14px;
}

@media only screen and (max-width: 991.98px) {
  footer .newsletter-wrapper .newsletter-input-text-wrapper .newsletter-input-error {
    padding: 0;
  }
}

footer .newsletter-wrapper .newsletter-input-text-wrapper .alert {
  margin-bottom: 0;
}

.footer-bottom {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.6);
  background-color: #0D1E26;
  font-size: 12px;
}

.footer-bottom__inner {
  padding-top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 1199.98px) {
  .footer-bottom__inner {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.footer-bottom__copyright {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 45%;
      -ms-flex: 0 0 45%;
          flex: 0 0 45%;
}

@media only screen and (max-width: 1199.98px) {
  .footer-bottom__copyright {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
  }
}

.footer-bottom__copyright-text {
  font-size: 12px;
  text-align: left;
}

.footer-bottom__links-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media only screen and (max-width: 1199.98px) {
  .footer-bottom__links-wrapper {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1;
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    padding-bottom: 10px;
  }
}

.footer-bottom__links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media only screen and (max-width: 1199.98px) {
  .footer-bottom__links {
    -webkit-box-pack: left;
    -webkit-justify-content: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
}

.footer-bottom__link {
  padding: 0 15px;
}

.partners .partners__inner {
  padding-top: 30px;
}

@media only screen and (max-width: 1199.98px) {
  .partners .partners__inner {
    width: 60%;
    padding-top: 15px;
  }
}

@media only screen and (max-width: 767.98px) {
  .partners .partners__inner {
    width: 100%;
  }
}

.partners .partners__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.partners .partners__text {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  line-height: 160%;
}

@media only screen and (max-width: 1199.98px) {
  .partners .partners__text {
    line-height: 180%;
  }
}

.partners .partners__logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}

@media only screen and (max-width: 1199.98px) {
  .partners .partners__logo {
    -webkit-flex-basis: 40%;
        -ms-flex-preferred-size: 40%;
            flex-basis: 40%;
  }
}

@media only screen and (max-width: 767.98px) {
  .partners .partners__logo {
    -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%;
  }
}

footer {
  background-color: #132935;
  color: #fff;
  padding-top: 100px;
  margin-top: 80px;
}

footer .footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
}

@media only screen and (max-width: 1199.98px) {
  footer .footer-wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

footer .footer-links-columns-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

footer .footer-links-columns-wrapper li {
  line-height: 110%;
  font-weight: 300;
  padding-bottom: 10px;
}

footer .footer-links-columns-wrapper li:last-child {
  padding-bottom: 0;
}

footer .footer-links-columns-wrapper li a {
  font-size: 14px;
}

@media only screen and (max-width: 767.98px) {
  footer .footer-links-columns-wrapper {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

footer .footer-links-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

@media only screen and (max-width: 767.98px) {
  footer .footer-links-row {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

footer .footer-links-row .footer-links-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  width: 50%;
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto;
}

footer .footer-links-row .footer-links-column:first-child {
  margin-right: 30px;
}

@media only screen and (max-width: 767.98px) {
  footer .footer-links-row .footer-links-column {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    padding-bottom: 20px;
  }
}

footer .footer-links {
  margin-right: 30px;
}

footer .footer-social-links {
  width: 160px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

footer .footer-social-links img:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 991.98px) {
  footer .footer-social-links {
    padding-top: 10px;
  }
}

footer .footer-contacts {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 33%;
      -ms-flex: 1 0 33%;
          flex: 1 0 33%;
  width: 33%;
  overflow: hidden;
  font-weight: 300;
  font-size: 14px;
}

footer .footer-contacts .footer-contacts-body {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 260px;
}

footer .footer-contacts .footer-contacts-body .footer-contacts__organization:first-line {
  line-height: 110%;
}

@media only screen and (max-width: 1199.98px) {
  footer .footer-contacts .footer-contacts-body {
    -webkit-box-pack: normal;
    -webkit-justify-content: normal;
        -ms-flex-pack: normal;
            justify-content: normal;
    height: auto;
  }
}

footer .footer-contacts .footer-logo-wrapper {
  position: relative;
}

footer .footer-contacts .footer-logo-wrapper .footer-logo {
  position: absolute;
  opacity: .3;
  bottom: -8px;
  right: 20px;
  width: 60%;
}

@media only screen and (max-width: 1199.98px) {
  footer .footer-contacts .footer-logo-wrapper {
    display: none;
  }
}

footer .footer-contacts-icon {
  padding-right: 5px;
}

footer a {
  color: white;
}

footer a:hover {
  color: #7c4dff;
}

footer .footer-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 10px;
}

footer .footer-title .footer-title-text {
  border-bottom: 1px solid #7c4dff;
  padding-bottom: 3px;
}

footer .footer-title .footer-title-text:hover {
  text-decoration: none;
  border-bottom: 1px solid #7c4dff !important;
}

footer .footer-title .footer-title-text:hover svg {
  fill: #7c4dff;
}

@media only screen and (max-width: 440px) {
  footer .footer-title .footer-title-text {
    padding-bottom: 0;
    line-height: 180%;
  }
  footer .footer-title .footer-title-text svg {
    display: none;
  }
}

footer .inactive-item {
  color: #6f8998;
}

.filters-ranking-bottom-panel {
  display: none;
  position: fixed;
  bottom: 15px;
  z-index: 98;
  width: 100%;
}

.ranking-sort {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 10px;
}

.ranking-sort label {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 0 !important;
}

.page-with-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

@media only screen and (max-width: 1199.98px) {
  .page-with-sidebar {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.page-with-sidebar .page-with-sidebar__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 500px;
      -ms-flex: 1 1 500px;
          flex: 1 1 500px;
  width: 500px;
}

@media only screen and (max-width: 1199.98px) {
  .page-with-sidebar .page-with-sidebar__content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 100%;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    width: 100%;
  }
}

.page-with-sidebar .page-with-sidebar__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  gap: 10px;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0 0 20px 0;
  background-color: #fff;
}

.page-with-sidebar .page-with-sidebar__header .sidebar {
  display: block;
}

.page-with-sidebar .page-with-sidebar__header .header__title {
  color: #222;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.page-with-sidebar .page-with-sidebar__header .header__filters-button-wrapper {
  width: 320px;
  margin-bottom: 0;
}

.page-with-sidebar .page-with-sidebar__header .header__sort-button-wrapper {
  margin-bottom: 0;
}

.page-with-sidebar .page-with-sidebar__header form.sidebar__filter {
  margin-bottom: 0;
}

.page-with-sidebar .page-with-sidebar__header .header__filters-button {
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  padding: 0;
}

.page-with-sidebar .page-with-sidebar__header .header__filters-button:hover, .page-with-sidebar .page-with-sidebar__header .header__filters-button:active, .page-with-sidebar .page-with-sidebar__header .header__filters-button:focus {
  background-color: inherit;
}

.page-with-sidebar .page-with-sidebar__header .header__filters-button .filters-button__text {
  margin-left: 10px;
}

.page-with-sidebar .page-with-sidebar__header .header__sort-button {
  margin-top: -5px;
}

.page-with-sidebar .page-with-sidebar__header .header__sort-button .fa {
  margin-top: -19px;
}

.page-with-sidebar .page-with-sidebar__header_bottom-line {
  border-bottom: 2px #F7F7FE solid;
}

.page-with-sidebar .page-with-sidebar__header_top-line {
  border-top: 2px #F7F7FE solid;
  padding-top: 10px;
  padding-bottom: 15px;
}

.page-with-sidebar .page-with-sidebar__sidebar {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 300px;
      -ms-flex: 0 0 300px;
          flex: 0 0 300px;
  max-width: 300px;
  z-index: 100;
  margin-right: 20px;
}

@media only screen and (min-width: 320px) {
  .filters-ranking-bottom-panel {
    bottom: -20px;
  }
  .page-with-sidebar .page-with-sidebar__header .header__title {
    font-size: 14px;
  }
  .page-with-sidebar .header__filters-button {
    pointer-events: all;
  }
}

@media only screen and (min-width: 550px) {
  .page-with-sidebar .page-with-sidebar__header .header__sort-button-wrapper {
    margin-left: auto;
  }
}

@media only screen and (min-width: 992px) {
  .filters-ranking-bottom-panel {
    bottom: -20px;
  }
  .page-with-sidebar .page-with-sidebar__header .header__title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .filters-ranking-bottom-panel {
    bottom: -35px;
  }
  .page-with-sidebar .header__filters-button {
    pointer-events: none;
  }
  .page-with-sidebar .header__sort-button {
    pointer-events: auto !important;
  }
}
